import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { InventoryDataContextProvider } from './context/InventoryDataContext';
import {
  ProfileDataContextProvider,
  type ProfileData,
} from './context/ProfileDataContext';
import { Routes } from './routes';
import { TOAST_DEFAULT_PROPS } from './utils/constants';

export interface InventoryAppProps {
  profileData: ProfileData | null;
}

export const InventoryApp = ({ profileData }: InventoryAppProps) => {
  return (
    <ProfileDataContextProvider profileData={profileData}>
      <InventoryDataContextProvider>
        <ToastContainer {...TOAST_DEFAULT_PROPS} />
        <Routes />
      </InventoryDataContextProvider>
    </ProfileDataContextProvider>
  );
};
