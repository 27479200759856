const subscribeToListenCustomEvent = (
  eventName: string,
  callback: () => void
) => document.addEventListener(eventName, callback);

const unsubscribeToListenCustomEvent = (
  eventName: string,
  callback: () => void
) => document.removeEventListener(eventName, callback);

const publishCustomEvent = (
  eventName: string,
  data?: Record<string, unknown>
) => {
  const customEvent = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(customEvent);
};

export const useCustomEvents = () => ({
  publishCustomEvent,
  subscribeToListenCustomEvent,
  unsubscribeToListenCustomEvent,
});
