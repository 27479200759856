export const HTTP_RESPONSES = {
  /**
   * @returns {number} 200
   */
  OK: 200,

  /**
   * @returns {number} 201
   */
  CREATED: 201,

  /**
   * @returns {number} 202
   */
  ACCEPTED: 202,

  /**
   * @returns {number} 204
   */
  NO_CONTENT: 204,

  /**
   * @returns {number} 400
   */
  BAD_REQUEST: 400,

  /**
   * @returns {number} 401
   */
  UNAUTHORIZED: 401,

  /**
   * @returns {number} 404
   */
  NOT_FOUND: 404,

  /**
   * @returns {number} 409
   */
  CONFLICT: 409,
};
