import { ErrorComponent } from '@Estoca/ui';
import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';

import { InventoryApp, type InventoryAppProps } from './InventoryApp';

const lifecycles = singleSpaReact<InventoryAppProps>({
  React,
  ReactDOM,
  rootComponent: InventoryApp,
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    return <ErrorComponent error={err} />;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
