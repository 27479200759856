/* eslint-disable react/jsx-no-constructed-context-values */
import {
  useEffect,
  useState,
  type Dispatch,
  type ReactNode,
  type SetStateAction,
} from 'react';
import { createContext } from 'use-context-selector';

import { Cookie } from '../helpers/cookie';
import { useCustomEvents } from '../hooks/use-custom-events';

type Group = {
  id: string;
  name: string;
};

export type Store = {
  id: string;
  name: string;
  order: number;
  is_test: boolean;
  is_churn: boolean;
  is_saas: boolean;
  legal_name?: string;
  preferred_warehouse_id?: string;
};

type Warehouses = {
  id: string;
  name: string;
};

type UserType = 'is_saas' | 'is_seller' | 'is_internal' | 'logistic_operator';

export type ProfileData = {
  id: string;
  email: UserType;
  user_type: string;
  first_name: string;
  last_name: string;
  groups: Group[];
  stores: Store[];
  warehouses?: Warehouses[];
  storeSelected?: Store;
};

interface ProfileDataContextData {
  profileData: ProfileData | null;
  setProfileData: Dispatch<SetStateAction<ProfileData | null>>;
}

export const ProfileDataContext = createContext({} as ProfileDataContextData);

export const ProfileDataContextProvider = ({
  profileData: initialProfileData,
  children,
}: {
  profileData: ProfileData | null;
  children: ReactNode;
}) => {
  const { subscribeToListenCustomEvent } = useCustomEvents();
  const [profileData, setProfileData] = useState<ProfileData | null>(
    initialProfileData || null
  );

  useEffect(() => {
    subscribeToListenCustomEvent('changedStore', () => {
      const storeSelected = JSON.parse(Cookie.get('storeSelected')) as Store;

      setProfileData(stateData => ({
        ...stateData,
        storeSelected,
      }));
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ProfileDataContext.Provider
      value={{
        profileData,
        setProfileData,
      }}
    >
      {children}
    </ProfileDataContext.Provider>
  );
};
