import { PageLoading } from '@Estoca/ui';
import { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

const InventoryHome = lazy(() =>
  import('../pages/InventoryHome').then(module => ({
    default: module.InventoryHome,
  }))
);

const InventoryControl = lazy(() =>
  import('../pages/InventoryControl').then(module => ({
    default: module.InventoryControl,
  }))
);

export const Routes = () => (
  <Suspense fallback={<PageLoading />}>
    <BrowserRouter>
      <Switch>
        <Route exact path="/inventory/performance" component={InventoryHome} />
        <Route exact path="/inventory/control" component={InventoryControl} />
      </Switch>
    </BrowserRouter>
  </Suspense>
);
