/* eslint-disable react/jsx-no-constructed-context-values */
import axios from 'axios';
import { useEffect, useState, type ReactNode } from 'react';
import { toast } from 'react-toastify';
import { createContext, useContextSelector } from 'use-context-selector';

import { type Status } from '../@types/status';
import { apiBaseURL } from '../lib/api';
import { HTTP_RESPONSES } from '../utils/responses';
import { ERROR_MESSAGES } from '../utils/toast-messages';

import { ProfileDataContext } from './ProfileDataContext';

export type FileDownloadType = 'csv' | 'xls';

export type InventoryProductType = 'product' | 'packaging' | 'raw_material';

type InventoryData = {
  product_id: string;
  created_at: string;
  human_id: string;
  product_name: string;
  product_sku: string;
  status: string;
  status_color: string;
  available_stock: number;
  sales: number;
  blocked: number;
  product_type: InventoryProductType;
  product_barcode: string | null;
  estimated_stock_days: number;
};

export type InventoryDataResponse = {
  page: number;
  per_page: number;
  total: number;
  data: InventoryData[];
};

type BatchAndExpirationData = {
  id: string;
  human_id: string;
  item_name: string;
  sku: string;
  validity_days: number;
  batch: string;
  validity_date: string;
  quantity: number;
};

type BatchAndExpirationState = {
  page: number;
  per_page: number;
  total: number;
  data: BatchAndExpirationData[];
};

export type InventoryTotalStatusDataResponse = {
  total_product: number;
  total_packaging: number;
  total_raw_material: number;
};

export type OrderBy = 'asc' | 'desc';

type TypeFilterLabel = 'all' | InventoryProductType;

export type TypeFilter = {
  label: TypeFilterLabel;
  quantity: number;
};

type InventoryFiltersState = {
  page: number;
  per_page: number;
  order_by: OrderBy;
  type: TypeFilter;
  searchedProduct?: string;
};

type InventoryValidityFiltersState = {
  page: number;
  per_page: number;
};

export type GetInventoryDataProps = InventoryFiltersState & {
  fileDownloadType?: FileDownloadType;
  searchedProduct?: string;
  type?: TypeFilter;
};

export type GetValidityDataProps = InventoryValidityFiltersState & {
  fileDownloadType?: FileDownloadType;
};

export type GetInventoryTotalStatusDataProps = {
  searchedProduct?: string;
};

type TotalStatusDataState = InventoryTotalStatusDataResponse & {
  all: number;
};

type InventoryDataState = {
  orders: InventoryDataResponse | null;
  totalStatusData: TotalStatusDataState | null;
};

interface InventoryDataContextData {
  inventoryDataStatus: Status;
  inventoryTotalDataStatus: Status;

  inventoryData: InventoryDataState;
  getInventoryData: (props: GetInventoryDataProps) => Promise<void>;
  getInventoryTotalStatusData: (
    props: GetInventoryTotalStatusDataProps
  ) => Promise<void>;
  inventoryFilters: InventoryFiltersState;

  inventoryValidityData: BatchAndExpirationState | null;
  getValidityData: (props: GetValidityDataProps) => Promise<void>;
  inventoryValidityFilters: InventoryValidityFiltersState;
}

export const InventoryDataContext = createContext(
  {} as InventoryDataContextData
);

export const InventoryDataContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const storeSelected = useContextSelector(
    ProfileDataContext,
    ctx => ctx.profileData.storeSelected
  );

  const [inventoryDataStatus, setInventoryDataStatus] =
    useState<Status>('idle');
  const [inventoryTotalDataStatus, setInventoryTotalDataStatus] =
    useState<Status>('idle');

  const defaultInventoryFilters: InventoryFiltersState = {
    page: 1,
    per_page: 10,
    order_by: 'desc',
    type: {
      label: 'all',
      quantity: 0,
    },
    searchedProduct: '',
  };
  const [inventoryFilters, setInventoryFilters] =
    useState<InventoryFiltersState>(defaultInventoryFilters);
  const [inventoryData, setInventoryData] = useState<InventoryDataState>({
    orders: null,
    totalStatusData: null,
  });

  const defaultInventoryValidityFilters: InventoryValidityFiltersState = {
    page: 1,
    per_page: 10,
  };
  const [inventoryValidityFilters, setInventoryValidityFilters] =
    useState<InventoryValidityFiltersState>(defaultInventoryValidityFilters);
  const [inventoryValidityData, setInventoryValidityData] =
    useState<BatchAndExpirationState | null>(null);

  const getInventoryTotalStatusData = async ({
    searchedProduct,
  }: GetInventoryTotalStatusDataProps) => {
    setInventoryTotalDataStatus('loading');

    const params = Object.assign(
      {
        store_id: storeSelected.id,
      },
      searchedProduct && {
        q: searchedProduct.trim(),
      }
    );

    try {
      const {
        status,
        data: { data },
      } = await axios.get<{ data: InventoryTotalStatusDataResponse }>(
        `${apiBaseURL}/inventory/stock_total`,
        {
          params,
        }
      );

      if ([HTTP_RESPONSES.OK].includes(status)) {
        const quantities = Object.values(data);

        const totalAmount = quantities.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );

        setInventoryData(state => ({
          ...state,
          totalStatusData: {
            ...data,
            all: totalAmount,
          },
        }));
        setInventoryFilters(state => {
          if (state.type.label === 'all') {
            return {
              ...state,
              type: {
                ...state.type,
                quantity: totalAmount,
              },
            };
          }

          return state;
        });
        setInventoryTotalDataStatus('success');
      }
    } catch (error) {
      setInventoryTotalDataStatus('error');
      toast.error(ERROR_MESSAGES.inventoryTotalStatusData);
    }
  };

  const getInventoryData = async ({
    page,
    per_page,
    fileDownloadType,
    searchedProduct,
    order_by,
    type,
  }: GetInventoryDataProps) => {
    setInventoryDataStatus('loading');

    setInventoryFilters(state => ({
      page,
      per_page,
      order_by,
      type: type || state.type,
      searchedProduct,
    }));

    const shouldSendTypeProp = !!type?.label && type?.label !== 'all';

    const params = Object.assign(
      {
        store_id: storeSelected.id,
        page,
        per_page,
        order_by,
      },
      fileDownloadType && {
        format: fileDownloadType,
        per_page: inventoryData.orders.total,
      },
      searchedProduct && {
        q: searchedProduct.trim(),
      },
      shouldSendTypeProp && {
        product_type: type.label,
      }
    );

    try {
      const { status, data, headers } = await axios.get<
        InventoryDataResponse | Blob
      >(`${apiBaseURL}/inventory/stock_list`, {
        responseType: fileDownloadType ? 'blob' : undefined,
        params,
      });

      if ([HTTP_RESPONSES.OK].includes(status)) {
        if (data instanceof Blob) {
          const link = document.createElement('a');
          link.hidden = true;
          link.target = '_blank';
          link.rel = 'noreferrer';
          link.href = window.URL.createObjectURL(data);

          const filename = String(headers['content-disposition'])
            .split('filename=')[1]
            .split(';')[0];

          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          setInventoryData(state => ({ ...state, orders: data }));
        }

        setInventoryDataStatus('success');
      }
    } catch (error) {
      setInventoryDataStatus('error');
      toast.error(ERROR_MESSAGES.inventoryData);
    }
  };

  const getValidityData = async ({
    page,
    per_page,
    fileDownloadType,
  }: GetValidityDataProps) => {
    setInventoryDataStatus('loading');

    setInventoryValidityFilters({
      page,
      per_page,
    });

    const params = Object.assign(
      {
        store_id: storeSelected.id,
        page,
        per_page,
      },
      fileDownloadType && {
        format: fileDownloadType,
        per_page: inventoryValidityData.total,
      }
    );

    try {
      const { status, data, headers } = await axios.get<
        BatchAndExpirationState | Blob
      >(`${apiBaseURL}/inbound/orders/validity`, {
        responseType: fileDownloadType ? 'blob' : undefined,
        params,
      });

      if ([HTTP_RESPONSES.OK].includes(status)) {
        if (data instanceof Blob) {
          const link = document.createElement('a');
          link.hidden = true;
          link.target = '_blank';
          link.rel = 'noreferrer';
          link.href = window.URL.createObjectURL(data);

          const filename = String(headers['content-disposition'])
            .split('filename=')[1]
            .split(';')[0];

          link.download = filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          setInventoryValidityData(data);
        }

        setInventoryDataStatus('success');
      }
    } catch (error) {
      setInventoryDataStatus('error');
      toast.error(ERROR_MESSAGES.validityData);
    }
  };

  useEffect(() => {
    const { pathname } = location;

    if (pathname.includes('performance')) {
      getInventoryData(defaultInventoryFilters);
      getInventoryTotalStatusData({
        searchedProduct: defaultInventoryFilters.searchedProduct,
      });
    }

    if (pathname.includes('control')) {
      getValidityData(defaultInventoryValidityFilters);
    }
  }, [storeSelected.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <InventoryDataContext.Provider
      value={{
        inventoryDataStatus,
        inventoryTotalDataStatus,

        inventoryData,
        getInventoryData,
        getInventoryTotalStatusData,
        inventoryFilters,

        inventoryValidityData,
        getValidityData,
        inventoryValidityFilters,
      }}
    >
      {children}
    </InventoryDataContext.Provider>
  );
};
