import { type ToastContainerProps } from 'react-toastify';

import {
  type InventoryProductType,
  type OrderBy,
} from '../context/InventoryDataContext';

export const FIFTEEN_MINUTES = 1000 * 60 * 15;

export const TOAST_DEFAULT_PROPS: Partial<ToastContainerProps> = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: true,
  closeOnClick: true,
  pauseOnHover: true,
  pauseOnFocusLoss: true,
  draggable: true,
};

export const DD_MM_AAAA_FORMAT_OPTION: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};

export const DD_OF_MM_OF_YYY_FORMAT_OPTION: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

export const REGULAR_EXPRESSIONS = {
  hyphen: /-/g,
  nonDigit: /\D/g,
} as const;

export const INVENTORY_STATUS_TRANSLATIONS: {
  [key in InventoryProductType]: string;
} = {
  product: 'Produto',
  packaging: 'Embalagem',
  raw_material: 'Insumo',
} as const;

export const INVENTORY_STATUS_TITLE_TRANSLATIONS: {
  [key in InventoryProductType | 'all']: string;
} = {
  all: 'Todos os tipos',
  product: 'Produtos',
  packaging: 'Embalagens',
  raw_material: 'Insumos',
} as const;

export const INVENTORY_SORT_OPTIONS_TRANSLATIONS: {
  [key in OrderBy]: string;
} = {
  desc: 'Mais recente',
  asc: 'Mais antigo',
} as const;
